<template>
   <!-- 选择支付方式 默认选中在线支付 -->
    <el-dialog class="dialog-for-month-pay" :visible="montyPayVisiable" :destroy-on-close="false" :show-close="true"
      :append-to-body="false" :before-close="handleBeforeClose" width="640px" top="35vh">
      <!-- 二维码区域 在线支付-->
      <div class="month-pay-container">
        <div v-if="alipayCode && alipayCode !== ''" class="qr-code">
          <div class="scande_border">
            <iframe id="bdIframe" :src="alipayCode" frameborder="0" width="300" height="400" scrolling="no"/>
          </div>
          <div v-if="refreshMask" class="refresh-mask" @click="handleRefresh"><i class="el-icon-refresh"></i></div>
        </div>
        <div class="qr-info">
          <div class="pay-amount">实付金额：<span><i>￥</i>{{price}}</span></div>
          <div class="ali">
            <img src="@/assets/image/alipaypic.png" alt=""/>
            <span>使用支付宝扫码签约付款</span>
          </div>
          <p>支付即视为您同意
            <a class="link-type" :href="userProtocol" target="_blank">《PlayList会员用户协议》</a>
            <a class="link-type" :href="autoRenewalCertificate" target="_blank">《PlayList自动续费协议》</a>
          </p>
        </div>
      </div>
      <div class="qr-tip">* 支付宝签约支付成功后在 <span @click="goMine">个人中心-我的会员</span> 中查看已购会员</div>
    </el-dialog>
</template>

<script>
import { autoRenewalCertificate, userProtocol } from '@/config'
export default {
  name: 'MontyPay',
  components: {
  },
  props: {
    alipayCode: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      autoRenewalCertificate,
      userProtocol,
      initAliCodeTimer: null,
      price: '',
      montyPayVisiable: false,
      refreshMask: false //二维码失效刷新
    }
  },
  watch: {
    alipayCode: {
      handler(newVal) {
        if(newVal){
          this.initAliCodeTimer = setTimeout(() => {
            this.refreshMask = true
          }, 60000);
        }
      },
      immediate: true
    }
  },
  methods: {
    goMine(){
      this.handleBeforeClose()
      this.$router.push({
        path: `/member/center/equity`
      })
    },
    handleOpen(price){
      this.price = price
      this.montyPayVisiable = true
    },
    handleBeforeClose() {
      clearTimeout(this.initAliCodeTimer)
      this.montyPayVisiable = false
      this.$emit('close')
    },
    handleRefresh(){
      clearTimeout(this.initAliCodeTimer)
      this.refreshMask = false
      this.$emit('refresh')
    },
  }
}
</script>
<style lang="scss" scoped>
.dialog-for-month-pay{
  /deep/ {
    .el-dialog {
      position: relative;
      border-radius: 5px;
      .el-dialog__header{
        display: none;
      }
    }
    .el-dialog__body{
      padding: 24px 40px 20px;
    }
  }
}
.month-pay-container{
  display: flex;
  align-items: center;
  .qr-code{
    width:120px;
    height:120px;
    overflow:hidden;
    border: 1px solid rgba(195,195,205,.6);
    position: relative;
    .scande_border{
      width:300px;
      height:400px;
      margin-top: -184px;
      margin-left: -91.5px;
      iframe {
        transform: scale(.6);
      }
    }
    .refresh-mask{
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      background-color: #979797;
      opacity: .8;
      .el-icon-refresh{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        color: #333;
      }
    }
  }
  .qr-info{
    margin-left: 20px;
    color: #999;
    font-size: 14px;
    .pay-amount{
      color: #333333;
      span{
        font-size: 30px;
        color: #E52A0D;
        font-weight: bold;
        i{
          font-style: normal;
          font-size: 14px;
        }
      }
    }
    .ali{
      display: flex;
      align-items: center;
      margin: 16px 0 10px;
      img{
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
    p{
      font-size: 12px;
    }
  }
}
.qr-tip{
  margin-top: 10px;
  color: #999999;
  font-size: 12px;
  span{
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
