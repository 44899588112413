<template>
   <el-dialog class="dialog-for-creator" :visible="ladderTopay" :destroy-on-close="false" :show-close="false"
      :append-to-body="false" :before-close="handleClosecreator" center>
      <div class="creator_dialog">
        <div class="creator_content">
          <img src="@/assets/image/pay_bj.png" alt="" @click="handleClosecreator" />
        </div>
        <div class="dialog_content" v-for="(i, index) in paymentList" :key="index">
          <div class="top_content">
            <div class="top_text">
              <p class="past_price">
                <span style="color: rgba(0, 0, 0, 0.85)">会员{{ paymentList.name }}，</span>
                <span class="yuanjia">原价
                  <span class="m_m"></span>
                </span>
                <span class="last_year">{{
                  paymentList.use_mode == 2
                  ? Number(paymentList.origin_goods.price)
                  : paymentList.use_mod == 3
                    ? Number(paymentList.origin_goods.price)
                    : Number(paymentList.origin_goods.price)
                }}/{{ paymentList.use_mode == '1' ? '月' : paymentList.use_mode == '2' ? '半年' : '年'}}</span>
              </p>
              <p class="discounts_price">
                <span class="ActivityPrice">活动优惠价</span>
                <span class="moy">￥</span>
                <span class="current_year">{{ Number(paymentList.price)
                }}<span class="current_price"><span>/{{ paymentList.use_mode == '1' ? '月' : paymentList.use_mode == '2' ? '半年' : '年'}}</span></span>
                </span>
              </p>
            </div>
            <div class="top_pic">
              <img src="@/assets/image/countdown.png" />
              <div class="offset">
                限时特惠<br /><span class="reduce">立减{{
                  Number(paymentList.origin_goods.price - paymentList.price)
                }}</span>
              </div>
            </div>
          </div>
          <div class="bottom_content">
            <div class="table_col">
              <table>
                <tr class="table_bg" style="background: linear-gradient(270deg,#fff9f3 0%,#ffebd1 100%);">
                  <td style="background: #fff6eb"></td>
                  <td style="font-size: 12px">价格(元)</td>
                  <td style="font-size: 12px">下载次数</td>
                  <td style="font-size: 12px">每日上限</td>
                </tr>
                <tr class="table_bg" style="background: #fffaf6">
                  <td style="font-weight: 400; font-size: 12px">
                    {{ paymentList.use_mode == 2 ? '月付' : '半年会员' }}
                  </td>
                  <td style="font-weight: 400; color: #d79b42; font-size: 14px">
                    {{
                      paymentList.use_mode == 2
                      ? Number(goodsInfo.price) + '*6=' + isFlow(goodsInfo.price * 6)
                      : paymentList.use_mod == 3
                        ? Number(goodsInfo.price) + '*2=' + Number(goodsInfo.price) * 2
                        : Number(goodsInfo.price) + '*2=' + Number(goodsInfo.price) * 2
                    }}
                  </td>
                  <td style="font-weight: 400; color: #d79b42; font-size: 14px">
                    {{
                      paymentList.use_mode == 2
                      ? goodsInfo.down_count + '/月'
                      : paymentList.use_mod == 3
                        ? goodsInfo.down_count + '/半年'
                        : goodsInfo.down_count + '/半年'
                    }}
                  </td>
                  <td style="font-weight: 400; color: #d79b42; font-size: 14px">
                    {{
                      paymentList.use_mode == 2
                      ? goodsInfo.down_num
                      : paymentList.use_mod == 3
                        ? goodsInfo.down_num
                        : goodsInfo.down_num
                    }}
                  </td>
                </tr>
                <tr class="table_bg" style="background: linear-gradient(270deg,#fff9f3 30%,#ffebd1 100%);">
                  <td style="background: #fff6eb;/* color: #000000D9; */font-size: 12px;">
                    {{
                      paymentList.use_mode == 2
                      ? '半年会员'
                      : paymentList.use_mod == 3
                        ? '年会员'
                        : '年会员'
                    }}
                  </td>
                  <td style="font-weight: 400; color: #d79b42; font-size: 14px">
                    {{
                      paymentList.use_mode == 2
                      ? Number(paymentList.origin_goods.price)
                      : paymentList.use_mod == 3
                        ? Number(paymentList.origin_goods.price)
                        : Number(paymentList.origin_goods.price)
                    }}
                  </td>
                  <td style="font-weight: 400; color: #d79b42; font-size: 14px">
                    {{
                      paymentList.use_mode == 2
                      ? paymentList.origin_goods.down_count + '/半年'
                      : paymentList.use_mod == 3
                        ? paymentList.origin_goods.down_count == 'n'
                          ? '不限'
                          : paymentList.origin_goods.down_count
                        : paymentList.origin_goods.down_count == 'n'
                          ? '不限'
                          : paymentList.origin_goods.down_count
                    }}
                  </td>
                  <td style="font-weight: 400; color: #d79b42; font-size: 14px">
                    {{
                      paymentList.use_mode == 2
                      ? paymentList.origin_goods.down_num
                      : paymentList.use_mod == 3
                        ? paymentList.origin_goods.down_num
                        : paymentList.origin_goods.down_num
                    }}
                  </td>
                </tr>
                <tr class="table_bg" style="background: #fffaf6">
                  <td style="font-size: 12px">
                    {{ paymentList.name }}
                  </td>
                  <td style="font-weight: 500; color: #E52A0D; font-size: 14px">
                    {{ Number(paymentList.price) }}
                  </td>
                  <td v-if="paymentList.down_count" style="font-weight: 500; color: #ff2f0f; font-size: 14px">
                    {{
                      paymentList.use_mode == 2
                      ? Math.ceil(paymentList.down_count / 6) + '/月'
                      : paymentList.use_mode == 3
                        ? Math.ceil(paymentList.down_count / 2) + '/半年'
                        : paymentList.down_count
                    }}
                  </td>
                  <td style="font-weight: 500; color: #d79b42; font-size: 14px">
                    {{ paymentList.down_num }}
                  </td>
                </tr>
              </table>
              <div class="topayBtn">
                <el-button @click="handlePay(1)" class="jsck">
                  狠心拒绝,付{{ goodsInfo.name }}</el-button>
                <el-button class="btn_pay" id="nttn" type="primary" @click="handlePay(2)">立即抢购
                  <img class="ldni" src="@/assets/image/fast_pay.png" style="position: absolute; top: 12px; right: 9px" />
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
</template>

<script>

export default {
  name: 'LadderPay',
  components: {
  },
  data() {
    return {
      goodsInfo: {},
      paymentList: {},
      ladderTopay: false
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    isFlow(num) {
      if (!isNaN(num)) {
        return (num + '').indexOf('.') != -1 ? num.toFixed(1) : num
      }
    },
    handleOpen(data, order_id){
      this.paymentList = data.rel_goods
      this.goodsInfo = data
      this.upOrderId = order_id
      this.ladderTopay = true
    },
    handleClosecreator() {
      this.ladderTopay = false
    },
    handlePay(type){
      this.$emit('ladderPay', {
        type,
        // goodId: this.goodsInfo.goods_id,
        // newGoodId: this.goodsInfo.rel_goods.goods_id,
        relGood: type == 1 ? this.goodsInfo : this.goodsInfo.rel_goods,
        orderId: this.upOrderId
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-for-creator {
  /deep/.el-dialog {
    width: 499px;
    background: none !important;
    box-shadow: none !important;

    .el-dialog__body {
      padding: 0px !important;
    }
  }
}
.el-dialog {
  background: none !important;
  box-shadow: none !important;
  width: 499px;
  height: 522px;
  margin-top: 0 !important;
  box-shadow: none !important;
  position: relative;

  .creator_dialog {
    width: 100%;
    height: 100%;
    .creator_content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 499px;
        cursor: pointer;
      }
    }

    .dialog_content {
      width: 500px;
      height: 300px;
      position: absolute;
      top: 70px;
      left: 35px;

      .top_content {
        width: 500px;

        .top_text {
          width: 260px;
          float: left;
          padding-left: 18px;
          margin-top: 5px;

          .past_price {
            font-size: 14px;
            // color: #000000;
            position: relative;

            .yuanjia {
              position: relative;
              color: rgba(0, 0, 0, 0.85) !important;

              .m_m {
                min-width: 60px;
                height: 2px;
                background: linear-gradient(to top right, #ffa950, #ff6a1b);
                position: absolute;
                top: 8px;
                left: 33px;
              }
            }
          }

          .last_year {
            font-size: 20px;
            //font-weight: bold;
            margin-left: 5px;
            background: linear-gradient(to top right, #ffa950, #ff6a1b);
            -webkit-background-clip: text;
            letter-spacing: 2px;
            color: transparent;
          }

          .discounts_price {
            width: 297px;
            display: flex;

            .ActivityPrice {
              font-size: 20px;
              color: #fff;
              font-weight: bold;
              margin-top: 16px;
            }
          }

          .current_year {
            font-size: 48px;
            color: transparent;
            background: linear-gradient(to top right, #ff2f0fff, #ff663fff);
            -webkit-background-clip: text;
            font-weight: bold;

            .current_price {
              font-size: 20px;
              color: #7e7e7e !important;
              font-weight: 400;
              margin-left: 5px;
              letter-spacing: 2px;
            }
          }

          .moy {
            font-size: 20px;
            color: #7e7e7e;
            line-height: 24px;
            font-weight: bold;
            // text-shadow: 1px 2px 2px rgba(111, 67, 9, 0.3);
            color: #ff663f;
            // background: linear-gradient(180deg, #ff663f 0%, #E52A0D 100%);
            margin: 7px 0 0 5px;
          }
        }

        .top_pic {
          float: left;
          margin-top: -58px;
          margin-bottom: 13px;
          margin-left: 14px;
          position: relative;

          &>img {
            //width: 156px;
            height: 163px;
          }

          .offset {
            position: absolute;
            top: 75px;
            left: 33px;
            font-size: 18px;
            color: #ffff;

            .reduce {
              font-size: 22px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
        }
      }

      .bottom_content {
        width: 500px;
        margin-top: 50px;
        clear: both;

        .table_col {
          width: 422px;
          text-align: center;

          table {
            border-collapse: collapse;
            border-spacing: 0;
          }

          tr {
            width: 600px;
            height: 30px;
            font-size: 12px;
          }

          td {
            width: 200px;
            height: 30px;
            font-size: 12px;
            margin-left: 20px;
          }

          .topayBtn {
            position: absolute;
            left: 41%;
            bottom: 0px;
            transform: translate(-50%, -0%);
            display: flex;

            .jsck {
              color: rgba(0, 0, 0, 0.5);
              font-size: 14px;
            }

            #nttn {
              font-size: 16px;
              position: relative;
              padding-right: 30px;
            }

            .btn_pay {
              background: -webkit-linear-gradient(left, #fe7273ff, #fc4243ff);
            }
          }
        }

        .table_bg {
          font-family: PingFangSC-Medium, PingFang SC;
          //background: linear-gradient(to top right, #fff9f3, #ffebd1);
          background: -webkit-linear-gradient(right, #fff9f3, #ffebd1);
          font-size: 14px;
          //background-color:rgba(255, 235, 209, 1)
        }
      }
    }
  }
}
</style>
